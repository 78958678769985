import { graphql, useStaticQuery } from "gatsby";
import { throttle } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

export type ScreenSize = "mobile" | "tablet" | "restricted" | "full";

type ScreenSizeResponse = {
  screenSize: ScreenSize;
  screenSizeRaw: number;
  initialRender: boolean;
};

type WindowDimensions = {
  height: number;
  width: number;
};

function getWindowDimensions(): WindowDimensions {
  const { innerHeight, innerWidth } = window;

  return {
    width: innerWidth,
    height: innerHeight,
  };
}

const getScreenSize = (windowDimensions: { height: number; width: number }) => {
  let resScreenSize: ScreenSize = "full";

  if (windowDimensions.width <= 500) {
    resScreenSize = "mobile";
  } else if (windowDimensions.width <= 800) {
    resScreenSize = "tablet";
  } else if (windowDimensions.width <= 1250) {
    resScreenSize = "restricted";
  }

  return resScreenSize;
};

export const useScreenSize = (): ScreenSizeResponse => {
  const [windowDimensions, setWindowDimensions] = useState<
    WindowDimensions | undefined
  >(undefined);

  const [screenSize, setScreenSize] = useState<ScreenSize | undefined>(
    undefined
  );

  useEffect(() => {
    if (windowDimensions) {
      setScreenSize(getScreenSize(windowDimensions));
    }
  }, [windowDimensions]);

  useEffect(() => {
    setWindowDimensions(getWindowDimensions());
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    screenSize: screenSize ? screenSize : "full",
    screenSizeRaw: windowDimensions ? windowDimensions.width : 1920,
    initialRender: !!screenSize,
  };
};

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  return data.site.siteMetadata;
};

export const useThrottle = (fn: (...args: any[]) => any, delay: number) => {
  const callbackRef = useRef(fn);
  useEffect(() => {
    callbackRef.current = fn;
  });

  return useCallback(
    throttle((...args) => callbackRef.current(...args), delay),
    [delay]
  );
};
